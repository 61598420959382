// 国有企业
<template>
  <Atlas />
</template>

<script>
import Atlas from './components/Atlas.vue'
export default {
  components: {
    Atlas
  }
};
</script>

<style scoped lang="less">
</style>
